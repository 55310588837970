export interface MaintainItemsResponse {
    result: ItemType[];
    count: number;
}

export interface ItemType {
    id: string;
    name: string;
    itemNumber: number;
    category: string;
    categoryId: string;
    storage: string;
    storageId: string;
    status: string;
    statusId: string;
    shipping: string;
    subCategory: string;
    subCategoryId: string;
    itemPrice: number;
    com: string;
    reservedUserName: string;
    reservationNumber: string;
    itemImageId: string;
    bringCustomer?: {
        id: string;
        bringCustomerNumber: string;
        firstName: string;
        name: string;
    };
    previewImage: {
        id: string;
        fileName: string;
    };
    refund: OrderItemRefundDto;
}

export interface OrderItemRefundDto {
    orderId: string;
    status: StripeRefundStatus;
    amount: number;
    createdAt: Date;
    modifiedAt: Date;
}

export enum StripeRefundStatus {
    requested,
    declined,
    refunded,
}

export enum StripePaymentStatus {
    pending,
    paid,
    canceled,
}
